import React from 'react';
import { Link, withRouter } from 'react-router-dom';

import getBusinessHours from '../../utils';
import { SocialIcons } from '../micro';

const ContactCard = () => (
  <div
    className="row"
    style={{
      overflow: 'hidden',
      margin: 'auto',
      background: '#ECE7E2',
      padding: '0 6px',
      maxWidth: 580,
    }}
  >

    <div className="col" style={{ width: '47%', textAlign: 'right', whiteSpace: 'nowrap' }}>

      <div
        style={{
          marginBottom: 20,
          marginLeft: 'auto',
          padding: '9px 0',
          borderRadius: 3,
          width: '90%',
          textAlign: 'center',
          backgroundColor: getBusinessHours.isCurrentlyOpen() ? '#A0C563' : '#555558',
          border: '1px solid rgba(7,7,7,0.06)',
          boxShadow: '0 2px 10px 0 rgba(0,0,0,0.09)',
          textShadow: '0 2px 0 0 black',
          fontFamily: '"Open sans", Helvetica, sans-serif',
          fontSize: 16,
          color: '#fff',
          whiteSpace: 'nowrap',
        }}
      >

        <span
          style={{
            color: '#fff',
            fontSize: '0.8em',
            fontWeight: 'bold',
            textTransform: 'uppercase',
          }}
        >
          {getBusinessHours.isCurrentlyOpen() ? 'open' : 'closed'}
        </span>

        <span
          style={{
            position: 'relative',
            top: -1,
            display: 'inline-block',
            fontSize: 12,
            marginLeft: 10,
            color: getBusinessHours.isCurrentlyOpen() ? '#e7f2d6' : '#ebebeb'
          }}
        >
          {getBusinessHours.isCurrentlyOpen()
            ? getBusinessHours.getDayMeta().whileOpenMsg
            : getBusinessHours.getDayMeta().whileClosedMsg
          }
        </span>

      </div>

      <ul>
        <li className="header">Kitchen Hours</li>
        <li><span>Everyday:</span> 8am - 3pm</li>
        {/* <li><span>Thurs - Sat:</span> 8am - 3pm</li> */}
        {/* <li>5pm - 9pm</li> */}
      </ul>

      <ul>
        <li className="header">Cafe Hours</li>
        <li><span>Mon - Fri:</span> 6am - 3pm</li>
        <li><span>Sat & Sun:</span> 7am - 3pm</li>
        {/* <li><span>Thurs & Fri:</span> 6am - 9pm</li> */}
        {/* <li><span>Sat:</span> 7am - 9pm</li> */}
      </ul>

    </div>

    <div
      className="col"
      style={{
        width: '46%',
        marginLeft: 20,
        textAlign: 'left',
      }}
    >

      <Link to="/jobs" style={{ fontSize: 14, margin: 0, padding: 0, fontWeight: 'bold', marginBottom: 20, display: 'block', zIndex: 9999 }}>Job Opportunities</Link>

      <SocialIcons />

      <ul style={{ marginBottom: 10, position: 'relative', top: 3, }}>
        <li className="header">Location</li>
        <li>2894 Main St.</li>
        <li>East Troy, WI 53120</li>
      </ul>

      <a
        href="https://goo.gl/maps/6Tgpv8UAdbw"
        rel="noopener noreferrer"
        style={{
          display: 'block',
          padding: '9px 0',
          border: 0,
          fontSize: 14,
          fontFamily: '"Open sans", Helvetica, sans-serif',
          background: '#3C5C76',
          textAlign: 'center',
          width: '100%',
          borderRadius: 5,
          marginBottom: 13,
          marginTop: 10,
          color: '#fff',
        }}
      >
        Get Directions
      </a>

      <ul>
        <li className="header">Contacts</li>
        <li><a href="tel:262-642-9600">(262) 642-9600</a></li>
        <li><a href="mailto:cafe@2894onmain.com">cafe@2894onmain.com</a></li>
      </ul>

    </div>

  </div>
)

export default withRouter(ContactCard)
